import { memo } from 'react';

import { switzerlandMapData } from '@data/maps.ts';
import { MapTooltip } from '../MapTooltip';
import './SwitzerlandMap.css';

const SwitzerlandMap = () => {
    return (
        <div className="w-container interactive-map-container">
            <div className="map-container map-container-swiss swiss ch">
                <picture>
                    <source
                        srcSet={switzerlandMapData.mapImage}
                        data-no-lazy="1"
                        type="image/webp"
                    />
                    <img
                        src={switzerlandMapData.mapImageFallback}
                        alt="germany-map"
                        data-no-lazy="1"
                        className="map-image"
                    />
                </picture>
                {switzerlandMapData.states.map(({ name, numberOfCustomers, svg: Map, id }) => (
                    <MapTooltip
                        key={id}
                        className={`map-state ${id} w-inline-block`}
                        delay={200}
                        numberOfCustomers={numberOfCustomers}
                        title={name}
                        contents={<Map />}
                    />
                ))}
            </div>
        </div>
    );
};

export default memo(SwitzerlandMap);
