import { memo, useState } from 'react';
import { Button } from '@components/common/Button';
import {
    AustriaMap,
    GermanyMap,
    SwitzerlandMap,
} from '@components/CustomersGeographicSection/CountryMaps';
import GermanyFlag from '@assets/germany-flag.svg';
import AustriaFlag from '@assets/austria-flag.svg';
import SwitzerlandFlag from '@assets/switzerland-flag.svg';
import styles from './CustomerGeographicSectionContents.module.scss';
import { APP_LOCALE } from '@utils/constants';

const CustomerGeographicSectionContents = ({ headline, title, description }) => {
    const [selectedCountry, setSelectedCountry] = useState(APP_LOCALE.toLowerCase());

    return (
        <div className={styles.customerGeographicSectionContents}>
            <div className={styles.customerGeographicSectionContentsTexts}>
                <h3 className="gradient-text">{headline}</h3>
                <h2 className={styles.customerGeographicSectionHeading}>{title}</h2>
                <span className={styles.customerGeographicSectionDescription}>{description}</span>

                <div className={styles.customerGeographicSectionContentsButtons}>
                    {countries
                        .sort((a, b) => (a.id === APP_LOCALE.toLowerCase() ? -1 : 1))
                        .map(country => (
                            <Button
                                key={country.id}
                                variant="gradient-outlined"
                                active={selectedCountry === country.id}
                                onClick={() => setSelectedCountry(country.id)}>
                                <span className={styles.countryButtonIcon}>
                                    <img src={country.icon.src} alt="flag" />
                                </span>{' '}
                                {country.name}
                            </Button>
                        ))}
                </div>
            </div>
            <div className={styles.customerGeographicSectionContentsMapContainer}>
                {idToCountryMap[selectedCountry as keyof typeof idToCountryMap]}
            </div>
        </div>
    );
};

const idToCountryMap = {
    de: <GermanyMap />,
    at: <AustriaMap />,
    ch: <SwitzerlandMap />,
};

const countries = [
    {
        id: 'de',
        name: 'Deutschland',
        icon: GermanyFlag,
    },
    {
        id: 'at',
        name: 'Österreich',
        icon: AustriaFlag,
    },
    {
        id: 'ch',
        name: 'Schweiz',
        icon: SwitzerlandFlag,
    },
];

// sorted array, where the first country is the default selected country (based on the APP_LOCALE)
const sortedCountries = countries.sort((a, b) => (a.id === APP_LOCALE.toLowerCase() ? -1 : 1));

export default memo(CustomerGeographicSectionContents);
