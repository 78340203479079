import GermanyMapImage from '@assets/maps/map-de.webp';
import GermanyMapImageFallback from '@assets/maps/map-de.png';
import Bradenburge from '@assets/maps/germany/brandenburg.svg?react';
import Thüringen from '@assets/maps/germany/thüringen.svg?react';
import Berlin from '@assets/maps/germany/berlin.svg?react';
import Sachsen from '@assets/maps/germany/sachsen.svg?react';
import SachsenAnhalt from '@assets/maps/germany/sachsen-anhalt.svg?react';
import SchleswigHolstein from '@assets/maps/germany/schleswig-holstein.svg?react';
import RheinlandPfalz from '@assets/maps/germany/rheinland-pfalz.svg?react';
import Saarland from '@assets/maps/germany/saarland.svg?react';
import Bayern from '@assets/maps/germany/bayern.svg?react';
import BadenWürttemberg from '@assets/maps/germany/baden-württemberg.svg?react';
import Hessen from '@assets/maps/germany/hessen.svg?react';
import NordrheinWestfalen from '@assets/maps/germany/nordrhein-westfalen.svg?react';
import Niedersachsen from '@assets/maps/germany/niedersachsen.svg?react';
import Bremen from '@assets/maps/germany/bremen.svg?react';
import Hamburg from '@assets/maps/germany/hamburg.svg?react';
import MecklenburgVorpommern from '@assets/maps/germany/mecklenburg-vorpommern.svg?react';

import AustriaMapImage from '@assets/maps/map-at.webp';
import AustriaMapImageFallback from '@assets/maps/map-at.png';
import Niederösterreich from '@assets/maps/austria/niederösterreich.svg?react';
import Wien from '@assets/maps/austria/wien.svg?react';
import Salzburg from '@assets/maps/austria/salzburg.svg?react';
import Tirol from '@assets/maps/austria/tirol.svg?react';
import Burgenland from '@assets/maps/austria/burgenland.svg?react';

import SwitzerlandMapImage from '@assets/maps/map-ch.webp';
import SwitzerlandMapImageFallback from '@assets/maps/map-ch.png';
import Luzem from '@assets/maps/switzerland/luzem.svg?react';
import Zurich from '@assets/maps/switzerland/zurich.svg?react';
import Uri from '@assets/maps/switzerland/uri.svg?react';
import Bern from '@assets/maps/switzerland/bern.svg?react';

export const germanyMapData = {
    mapImage: GermanyMapImage.src,
    mapImageFallback: GermanyMapImageFallback.src,
    states: [
        {
            id: 'bb',
            name: 'Brandenburg',
            numberOfCustomers: '25+',
            svg: Bradenburge,
        },
        {
            id: 'th',
            name: 'Thüringen',
            numberOfCustomers: '20',
            svg: Thüringen,
        },
        {
            id: 'be',
            name: 'Berlin',
            numberOfCustomers: '31',
            svg: Berlin,
        },
        {
            id: 'sn',
            name: 'Sachsen',
            numberOfCustomers: '21',
            svg: Sachsen,
        },
        {
            id: 'st',
            name: 'Sachsen-Anhalt',
            numberOfCustomers: '13',
            svg: SachsenAnhalt,
        },
        {
            id: 'sh',
            name: 'Schleswig-Holstein',
            numberOfCustomers: '15',
            svg: SchleswigHolstein,
        },
        {
            id: 'rp',
            name: 'Rheinland-Pfalz',
            numberOfCustomers: '11',
            svg: RheinlandPfalz,
        },
        {
            id: 'sl',
            name: 'Saarland',
            numberOfCustomers: '7',
            svg: Saarland,
        },
        {
            id: 'by',
            name: 'Bayern',
            numberOfCustomers: '19',
            svg: Bayern,
        },
        {
            id: 'bw',
            name: 'Baden-Württemberg',
            numberOfCustomers: '25',
            svg: BadenWürttemberg,
        },
        {
            id: 'he',
            name: 'Hessen',
            numberOfCustomers: '29',
            svg: Hessen,
        },
        {
            id: 'nw',
            name: 'Nordrhein-Westfalen',
            numberOfCustomers: '29',
            svg: NordrheinWestfalen,
        },
        {
            id: 'ni',
            name: 'Niedersachsen',
            numberOfCustomers: '24',
            svg: Niedersachsen,
        },
        {
            id: 'hb',
            name: 'Bremen',
            numberOfCustomers: '17',
            svg: Bremen,
        },
        {
            id: 'hh',
            name: 'Hamburg',
            numberOfCustomers: '9',
            svg: Hamburg,
        },
        {
            id: 'mv',
            name: 'Mecklenburg-Vorpommern',
            numberOfCustomers: '23',
            svg: MecklenburgVorpommern,
        },
    ],
};

export const austriaMapData = {
    mapImage: AustriaMapImage.src,
    mapImageFallback: AustriaMapImageFallback.src,
    states: [
        {
            id: 'at-ni',
            name: 'Niederösterreich',
            numberOfCustomers: '8',
            svg: Niederösterreich,
        },
        {
            id: 'at-wi',
            name: 'Wien',
            numberOfCustomers: '12',
            svg: Wien,
        },
        {
            id: 'at-sa',
            name: 'Salzburg',
            numberOfCustomers: '5',
            svg: Salzburg,
        },
        {
            id: 'at-ti',
            name: 'Tirol',
            numberOfCustomers: '7',
            svg: Tirol,
        },
        {
            id: 'at-bu',
            name: 'Burgenland',
            numberOfCustomers: '5',
            svg: Burgenland,
        },
    ],
};

export const switzerlandMapData = {
    mapImage: SwitzerlandMapImage.src,
    mapImageFallback: SwitzerlandMapImageFallback.src,
    states: [
        {
            id: 'ch-lu',
            name: 'Luzem',
            numberOfCustomers: '7',
            svg: Luzem,
        },
        {
            id: 'ch-zu',
            name: 'Zurich',
            numberOfCustomers: '11',
            svg: Zurich,
        },
        {
            id: 'ch-ur',
            name: 'Uri',
            numberOfCustomers: '4',
            svg: Uri,
        },
        {
            id: 'ch-be',
            name: 'Bern',
            numberOfCustomers: '5',
            svg: Bern,
        },
    ],
};
