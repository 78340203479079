import { memo } from 'react';
import { Tooltip, type TooltipProps } from '@components/common';
import BuildingIcon from '@assets/building.svg';
import styles from './MapTooltip.module.scss';

type TooltipContentsProps = {
    title: string;
    numberOfCustomers: string;
};

const TooltipContents = memo(({ title, numberOfCustomers }: TooltipContentsProps) => {
    return (
        <div className={styles.tooltipContents}>
            <div className={styles.tooltipContentsHeader}>
                <img src={BuildingIcon.src} alt="building-icon" />
                {title}
            </div>
            <strong className={styles.tooltipContentsNumber}>{numberOfCustomers}</strong>
            <span className={styles.tooltipContentsNumberLabel}>Kunden</span>
        </div>
    );
});

const MapTooltip = ({
    title,
    numberOfCustomers,
    ...rest
}: TooltipContentsProps & Omit<TooltipProps, 'ref' | 'children'>) => {
    return (
        <Tooltip delay={200} {...rest}>
            <TooltipContents title={title} numberOfCustomers={numberOfCustomers} />
        </Tooltip>
    );
};

export default memo(MapTooltip);
