import { memo } from 'react';

import { austriaMapData } from '@data/maps.ts';
import { MapTooltip } from '../MapTooltip';
import './AutriaMap.css';

const AustriaMap = () => {
    return (
        <div className="w-container interactive-map-container">
            <div className="map-container map-container-austria austria at">
                <picture>
                    <source srcSet={austriaMapData.mapImage} data-no-lazy="1" type="image/webp" />
                    <img
                        src={austriaMapData.mapImageFallback}
                        alt="germany-map"
                        data-no-lazy="1"
                        className="map-image"
                    />
                </picture>
                {austriaMapData.states.map(({ name, numberOfCustomers, svg: Map, id }) => (
                    <MapTooltip
                        key={id}
                        className={`map-state ${id} w-inline-block`}
                        delay={200}
                        numberOfCustomers={numberOfCustomers}
                        title={name}
                        contents={<Map />}
                    />
                ))}
            </div>
        </div>
    );
};

export default memo(AustriaMap);
